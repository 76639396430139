import { confirmAlert } from "react-confirm-alert";
import QRCode from 'qrcode.react';
class CommonUtils {
  static mainSiteTitle = "Careermon";

  static displayConfirmAlert(
    message = "You don't have right to access this page",
    okOption = { label: "OK", className: "", onClick: () => {} },
    cancelOption = null
  ) {
    let listButton = [];
    okOption && listButton.push(okOption);
    cancelOption && listButton.push(cancelOption);
    confirmAlert({
      message: message,
      overlayClassName: "custom-ui",
      closeOnClickOutside: false,
      buttons: listButton,
    });
  }

  static displayConfirmAlertHaveNotRightPermission(onCallback = () => {}) {
    confirmAlert({
      message: "You don't have right to access this page",
      overlayClassName: "custom-ui",
      closeOnClickOutside: false,
      buttons: [{ label: "OK", onClick: onCallback }],
    });
  }
  static generateQR(text) {
    return <QRCode value={text} size={120} />;
  }
}

export default CommonUtils;
